.survey-breadcrumb-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    box-sizing: border-box;
    height: 65px;
    font-size: 17px;
    border-bottom: 1px solid $separator-color;
    width: 100%;
    padding-left: $spacing5;
    background: $white;
}

.survey-breadcrumb-row__left {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
}

.survey-breadcrumb-row__right {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: auto;
    // border-left: 1px solid $separator-color;

    .search-icon {
        width: 30px;
        height: 30px;
        cursor: pointer;
        padding: 0 10px;
    }
}

.survey-breadcrumb-row__item {
    text-decoration: none !important;
    padding: 0;
    position: relative;
    display: inline-block;
    color: $gray-color-40 !important;

    &--first {
        padding-left: $spacing5;
    }

    &.survey-breadcrumb-row__item--active {
        color: $base-80 !important;
        font-weight: 600;
    }
}

.lg-breadcrumb__arrow {
    position: relative;
    top: -1px;
}
