.survey-navigation-menu {
    display: flex;
    flex-flow: column;
    height: 100%;
    border-right: 1px solid $separator-color;
    background: white;
    flex-basis: 20%;
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 360px;
    max-width: 500px;
    flex-basis: 25%;
}

.survey-subject-actions__menu {
    width: $spacing5;
    height: $spacing5;
    &:hover,
    &.lg-icon-menu--active {
        background: $base-05;
        color: $icon-menu-color;
    }
}

.survey-navigation-menu__block {
    position: relative;
    border-bottom: 1px solid $separator-color;
    display: flex;
    flex-direction: column;
    height: $spacing8;
    justify-content: center;
    flex-shrink: 0;

    .survey-subject-actions {
        margin-left: auto;
        margin-right: $spacing3;
    }

    &--root {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 90px;

        .survey-status-indicator {
            margin-left: $spacing3;
            margin-right: 0;
        }
    }

    &--switch {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    a.survey-navigation-menu__block__link {
        display: block;
        text-decoration: none;
        padding: 0 $spacing3 0 $spacing3;
        box-sizing: border-box;
        width: 100%;
        line-height: $spacing6;
        color: $gray-color-80;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover {
            background: $base-05;
            color: $gray-color-80;
        }
        &:visited {
            color: $gray-color-80;
        }
    }

    &--root {
        a.survey-navigation-menu__block__link {
            padding-left: 0;
        }
        .lg-button {
            margin-left: $spacing1;
            .button .lg-icon {
                margin: 0;
            }
        }
    }

    lg-dropdown {
        margin: 0 $spacing3 0 $spacing3;
        width: auto;
        min-width: 0;
        flex-shrink: 1;
        flex-grow: 1;
        .lg-dropdown__text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.survey-navigation-menu__inner-block {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 0;
    flex-basis: auto;
    padding-top: 9px;
}

.survey-navigation-menu__inner-block__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: $spacing5;
    align-items: center;
    margin: 3px 0;
    &--tall {
        height: $spacing6;
    }
}

.survey-navigation-menu__sections {
    padding: 0;

    a.survey-navigation-menu__link {
        display: block;
        text-decoration: none;
        padding: 0 $spacing3 0 $spacing3;
        box-sizing: border-box;
        width: 100%;
        line-height: $spacing6;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $gray-color-80;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;

        &.survey-navigation-menu__link--active.survey-navigation-menu__link--active {
            background: $base-10;
        }
        &:hover,
        &--hovered {
            // font-weight: bold;
            background: $base-05;
            color: $gray-color-80;
        }
        &:visited {
            color: $gray-color-80;
        }

        &--subject {
            .survey-navigation-menu__link__expand {
                position: relative;
                left: -6px;
            }

            .survey-subject-actions {
                margin-left: auto;
                margin-right: 0;
                width: $spacing5;
                height: $spacing5;
                pointer-events: none;
                visibility: hidden;
            }

            lg-icon {
                flex-shrink: 0;
            }

            &:hover,
            &.survey-navigation-menu__link--hovered {
                .survey-subject-actions {
                    visibility: visible;
                    pointer-events: all;
                }
            }
        }

        &--active-subject {
            font-weight: bold;
        }

        .survey-interesting-dot {
            margin-left: -7px;
            margin-right: 7px;
        }

        .survey-navigation-menu__link__text {
            // min-width: 0;
            // flex-grow: 1;
            flex-shrink: 1;
            // flex-basis: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.survey-navigation-menu__sections__item {
    display: flex;
    flex-direction: row;
    height: $spacing6;
    overflow: hidden;
    cursor: pointer;

    .lg-icon--error {
        margin-left: $spacing1;
        flex-shrink: 0;
    }
}

.survey-navigation-menu__sections__item__children {
    margin-left: $spacing5;
    border-left: 1px solid $gray-color-10;
    display: flex;
    flex-direction: column;

    .lg-button {
        margin: $spacing2 #{($spacing6 - $spacing5) * 0.5 + $scrollbar-width} $spacing2 #{(
                $spacing6 - $spacing5
            ) * 0.5};

        .button .lg-icon {
            vertical-align: bottom;
        }
    }
}
.survey-navigation-menu__sections__item__children
    + .survey-navigation-menu__sections__item__children {
    border-top: 1px solid $gray-color-10;
}

.lg-scrollbar-is-hidden .survey-navigation-menu__sections__item__children .lg-button {
    margin-right: #{($spacing6 - $spacing5) * 0.5};
}

.survey-navigation-menu__scroller {
    margin: 0;
    height: 100%;
    width: auto;
    flex-grow: 1;
    flex-shrink: 1;
    &:before {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 6px;
        content: " ";
        background: linear-gradient(180deg, rgba(24, 23, 22, 0.06) 0%, rgba(24, 23, 22, 0) 100%);
        pointer-events: none;
    }
}

.survey-navigation-menu__scrollable {
    padding: 0;
}
