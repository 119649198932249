.codman-tool-icon {
    background: transparent url("./../../logos/tool-icon-codman.svg") no-repeat center center;
    background-size: 48px;
    margin: 8px auto;
}

.survey-tool-icon {
    background: transparent url("./../../logos/tool-icon-survey.svg") no-repeat center center;
    background-size: 48px;
    margin: 8px auto;
}

.data-connect-tool-icon {
    background: transparent url("./../../logos/tool-icon-data-connect.svg") no-repeat center center;
    background-size: 48px;
    margin: 8px auto;
}

.medicines-tool-icon {
    background: transparent url("./../../logos/tool-icon-medicines.svg") no-repeat center center;
    background-size: 48px;
    margin: 8px auto;
}
