.survey-input-control {
    @supports selector(div:has(input)) {
        &:has(.survey-input-control--prolonged) {
            width: 100%;
        }
    }

    input {
        display: inline-block;

        &.survey-input-control--prolonged {
            width: 100%;
        }
    }

    input[type="number"] {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .survey-input-control__error {
        display: inline-block;
        color: $error-color;
        vertical-align: baseline;
        margin-left: 16px;
    }
}
