.survey-about-dialog {
    &__logo {
        margin-bottom: 20px;
    }

    &__text {
        margin-bottom: 20px;

        img[alt$="<"] {
            float: left;
            margin: 0 $spacing3 $spacing3 0;
        }
        img[alt$=">"] {
            float: right;
            margin: 0 0 $spacing3 $spacing3;
        }

        &--long {
            margin-bottom: 0;
        }
    }
}
