.survey-color-dot {
    width: $spacing5;
    height: $spacing5;
    overflow: hidden;
    display: block;

    div {
        width: $spacing2;
        height: $spacing2;
        overflow: hidden;
        border-radius: $spacing2 * 0.5;
        margin: ($spacing5 - $spacing2) * 0.5;
    }
}
