.survey-paging {
    display: inline-block;
    line-height: 24px;
    margin: 2px 0;

    a {
        margin: 0 4px;
        &:first {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    a {
        display: inline-block;
        box-sizing: border-box;
        height: 24px;
        vertical-align: top;
        text-decoration: none !important;
        color: $gray-color-40;

        &.survey-paging__page {
            border: 1px solid $gray-color-10;
            padding: 0 4px;
        }

        .lg-icon {
            color: $base-60;
        }

        &.disabled {
            cursor: default;
            border-color: transparent;
            color: $gray-color-40;

            .lg-icon {
                color: $gray-color-5;
            }
        }

        &:hover:not(.disabled) {
            background: $gray-color-10;
        }
    }

    .survey-paging__dots {
        display: inline-block;
        height: 24px;
        vertical-align: top;
    }
}
