.survey-section {
    box-sizing: border-box;
    background: $white;
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;

    &--standalone {
        border: none;
        padding: 0;
    }
}

.survey-section__content {
    border: 1px solid $gray-color-10;
    border-radius: $panel-border-radius;
    padding: $spacing4;
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;

    .survey-section--standalone & {
        border: none;
        padding: 0;
    }
}

.survey-section__content__buttons {
    margin-top: $spacing4;
    display: flex;
    justify-content: flex-end;

    .lg-button {
        margin-left: $spacing4;
    }
    &__note {
        margin-left: $spacing2;
        align-self: center;
    }
}

.survey-section__category {
    display: flex;
    flex-direction: column;
    padding: $spacing5 0;

    &:first-of-type {
        padding-top: $spacing5 - $spacing1;
    }
    &:not(:last-child) {
        border-bottom: 1px solid $gray-color-10;
    }

    h3 {
        font-size: 16px;
        font-weight: 600;
        line-height: $spacing4;
        color: $gray-color-80;
        margin: $spacing1 0;
        white-space: pre-line;
    }
}
