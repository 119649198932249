.survey-checkbox-control {
    display: flex;
    flex-direction: column;
}

.survey-checkbox-control--horizontal {
    flex-direction: row;
}

.survey-checkbox-control__entry {
    padding: $spacing0 0;
    line-height: $spacing5;
    color: $gray-color-60;

    .lg-styled-checkbox > span {
        vertical-align: baseline;
    }

    .survey-checkbox-control--horizontal & {
        margin-right: $spacing5;
    }

    &--disabled {
        color: $gray-color-20;
    }

    &--selected {
        color: $gray-color-80;
    }
}
