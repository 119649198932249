body {
    min-height: 100%;
}

.body__content > .flex-flexible {
    overflow: auto;
    min-height: 100%;
}

.survey-body__holder {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    flex-grow: 1;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: $body-color;

    .body__content {
        // padding-top: $spacing5;
        padding: 0;
    }
}

.survey-padded {
    padding: $spacing5;
}

.survey-panel {
    border: 1px solid $gray-color-10;
    border-radius: $panel-border-radius;
    box-sizing: border-box;
    background: $white;

    &--fit-content {
        height: fit-content;
    }

    &--padded {
        padding: $spacing5;
    }

    &--half {
        width: 50%;
    }

    &--minimal {
        height: auto;
    }

    &--top-attachment {
        border-radius: 0 0 $panel-border-radius $panel-border-radius;
    }

    h2 {
        font-size: 19px;
        font-weight: 600;
        line-height: $spacing5;
        color: $gray-color-80;
    }

    .survey-panel__text {
        margin: $spacing5 0;
        color: $gray-color-60;
    }

    .survey-panel__error {
        margin: $spacing3 0;
        color: $error-color;
        font-weight: bold;
    }

    .survey-panel__old-survey-link {
        margin: $spacing3 0;
        color: $gray-color-60;
    }
}
