.validation-report-subject {
    margin-top: $spacing3;
}

.validation-report-subject__header {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    color: $gray-color-80;
    margin: $spacing3 $spacing3 $spacing2 $spacing3;
}

.validation-report-subject__section {
    display: block;
}

.validation-report-subject__section-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    &:hover {
        background-color: $base-10;
    }

    h2 {
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        color: $gray-color-80;
        margin: 0;
    }
    .button {
        margin-left: auto;
    }

    padding: $spacing2 $spacing3 $spacing2 $spacing3;
}

.validation-report-subject__variable {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: $spacing3;

    &:hover {
        background-color: $base-10;
    }

    label {
        width: 25%;
        display: block;
    }

    ul {
        display: block;
        margin: 0;

        li {
            margin-top: $spacing1;
            &:first-of-type {
                margin-top: 0;
            }
        }

        li.validation-report-subject__variable__error {
            color: $error-color;
        }
        li.validation-report-subject__variable__warning {
            color: $warning-color;
        }
    }
}
