// todo: move to the framework
$panel-border-radius: 4px;

// local
$separator-color: $gray-color-20;
$inactive-item-color: $gray-color-40;

.survey-red-text {
    color: $error-color;
}

.lg-dropdown.lg-dropdown--disabled {
    opacity: 0.65;
    color: black;

    .lg-dropdown__text.lg-dropdown__text--empty {
        color: black;
    }
}

.survey-reports-button .lg-icon {
    width: 38px;
    height: 38px;
    top: 7.5px;
    left: 13.5px;
}

.survey-crop-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 100%;
}

.lg-scrollable__holder.flexcol .lgVirtualForOf-holder {
    flex-shrink: 0;
}

.lg-snackbar {
    margin-left: 76px;
}

.lg-styled-radio input[type="radio"]:disabled + span,
.lg-styled-radio input[type="radio"]:disabled:checked + span {
    color: $gray-color-25;
}
