.survey-interesting-dot {
    display: inline-block;
}

.survey-interesting-dot__circle {
    border-radius: $spacing1;
    width: $spacing3;
    height: $spacing3;
    overflow: hidden;
    display: block;
}

.survey-interesting-dot-tooltip {
    padding: 4px;
    img {
        display: block;
    }
}
