.survey-image-radio-control {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.survey-image-radio-control__entry {
    padding: $spacing0 0;
    line-height: $spacing5;
    color: $gray-color-60;
    margin-right: $spacing5;

    & > label {
        border: 1px solid $gray-color-10;
        border-radius: $border-radius;
        display: flex;
        flex-direction: column;
        width: 237px;
    }

    .survey-image-radio-control__entry__image {
        opacity: 0.5;
        color: $gray-color-60;
    }

    .lg-styled-radio > span {
        vertical-align: top;
    }

    &--disabled {
        opacity: 0.65;
        color: black;
    }

    &--selected {
        color: $gray-color-80;
        & > label {
            border-color: $base-60;
        }
        &.survey-image-radio-control__entry--disabled {
            opacity: 0.65;
        }
    }
}

.survey-image-radio-control__entry__image {
    display: flex;
    flex-flow: row;
    height: 80px;
    align-items: center;
    align-self: center;
    padding: $spacing3 0 0;
}

.survey-image-radio-control__entry__input {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    margin: 0 $spacing3 $spacing1;
}
