.survey-search-dialog {
    height: 300px;
    display: flex;
    flex-direction: column;

    &__search {
        margin-bottom: $spacing3;
        display: flex;
        flex-direction: row;

        .input {
            margin-right: $spacing3;
            flex-grow: 1;
        }

        .search-button {
            display: block;
            overflow: hidden;

            .button {
                width: 100%;
            }
        }
    }

    &__result {
        height: 100%;
    }
}
