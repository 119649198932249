.survey-external-optionset-control {
    padding-top: $spacing1;
    &--top {
        padding-top: 0;
        padding-bottom: $spacing1;
    }
}

.survey-external-optionset-dialog {
    height: 400px;
    display: flex;
    flex-direction: column;

    &__description {
        margin: 20px 0;
    }
    &__search {
        border: 1px solid $gray-color-10;
        border-bottom: none;
        border-radius: $panel-border-radius $panel-border-radius 0 0;

        input {
            border-radius: $panel-border-radius $panel-border-radius 0 0;
            width: 100%;
            line-height: $spacing5;
            box-sizing: border-box;
        }
    }
    &__buttons {
        margin: $spacing2 0 0;

        .button--error {
            .lg-icon {
                position: relative;
                top: -1px;
            }
            &:hover:focus {
                box-shadow: 0 0 6px 2px rgba($error-color, 0.67);
            }
        }
    }
}
