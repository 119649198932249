.survey-page-scroller {
    // margin: -$spacing5;
    // height: calc(100% + #{2 * $spacing5});
    // width: calc(100% + #{2 * $spacing5});
    margin: 0;
    height: 100%;
    width: auto;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 80%;
    background: white;
}

.survey-page-content {
    padding: $spacing5;
}

.survey-list__header {
    padding: $spacing1 $spacing3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $base-10;

    &__title {
        margin-right: $spacing2;
        color: $base-80;
        font-weight: 700;
    }
}

.survey-page-buttons {
    padding-top: $spacing3;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.survey-navigation-buttons__right {
    margin-left: $spacing1;
}

.survey-create-error {
    color: $error-color;
}

.survey-delete__block {
    border: 1px solid $error-color;
    padding: $spacing1;
    margin-top: $spacing2;
    .survey-delete__label {
        color: $error-color;
    }
    h2 {
        color: $error-color;
        margin: 0;
    }
}
.survey-delete__block__children {
    margin-left: $spacing1;
}

.survey-print-iframe {
    visibility: hidden;
    height: 0;
    width: 0;
    position: absolute;
    border: 0;

    &--firefox {
        width: 1px;
        height: 100px;
        position: fixed;
        left: 0;
        top: 0;
        opacity: 0;
        border-width: 0;
        margin: 0;
        padding: 0;
    }
}

.survey-section-header-strip {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0 0 $spacing2 0;
}

h1.survey-section-header-strip__header {
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;
    color: $gray-color-80;
    position: relative;
    flex-grow: 1;
    margin: 0;
}

.survey-section-header-strip__image {
    margin-right: $spacing3;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.survey-section-header-strip__image-title {
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin-top: $spacing0;
    line-height: 1rem;
}

.survey-section-header-strip__buttons {
    position: absolute;
    right: 0;
    bottom: 0;
}

.lg-button--survey-overflow {
    display: inline-flex;
    // flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;

    a {
        overflow: hidden;
        display: inline-flex;

        .lg-icon {
            flex-shrink: 0;
        }

        span {
            flex-shrink: 1;
            flex-grow: 1;
            min-width: 0;
            flex-basis: auto;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.table__row--header--multirow {
    .table__column {
        height: auto;
    }
}

a.button.button--survey-multirow {
    display: inline-flex;
    min-height: $spacing5;
    height: auto;
    .lg-icon {
        align-self: center;
    }
}
