.survey-radio-control {
    display: flex;
    flex-direction: column;
}

.survey-radio-control--horizontal {
    flex-direction: row;
}

.survey-radio-control__entry {
    padding: $spacing0 0;
    line-height: $spacing5;
    color: $gray-color-60;

    .lg-styled-radio > span {
        vertical-align: top;
    }

    .survey-radio-control--horizontal & {
        margin-right: $spacing5;
    }

    &--disabled {
        color: black;
        opacity: 0.65;
    }

    &--selected {
        color: $gray-color-80;
    }
}
