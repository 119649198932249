.survey-dataset-control {
    display: flex;
    flex-direction: column;
}

.survey-dataset-control__header {
    height: $spacing6;
    line-height: $spacing6;
    box-sizing: border-box;
    padding: 0 $spacing2;
    font-weight: bold;
}

.survey-dataset-control__table {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing2;

    .lg-scrollable__holder {
        max-height: 150px;
    }

    &--tall .lg-scrollable__holder {
        max-height: calc(max(250px, 50vh));
    }

    .lg-scrollable {
        min-height: 28px;
    }

    .survey-dataset-control__table__item {
        height: 28px;
        line-height: 28px;
    }
}

.survey-dataset-control__list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border: 1px solid $separator-color;
    border-radius: $border-radius;
    margin-bottom: $spacing2;

    .survey-dataset-control__list__header {
        height: $spacing6;
        line-height: $spacing6;
        box-sizing: border-box;
        border-bottom: 1px solid $separator-color;
        padding: 0 $spacing2;
        font-weight: bold;
    }

    .survey-dataset-control__list__item {
        height: $spacing6;
        line-height: $spacing6;
        box-sizing: border-box;
        padding: 0 $spacing2;
    }
}
