@import "definitions";

.survey-variable {
    display: flex;
    flex-direction: column;
    margin: $spacing1 0;
}

.survey-variable__columns {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-items: flex-start;
}

.survey-variable__description {
    line-height: $spacing5;
    margin-top: $spacing2;
    margin-bottom: $spacing2;
}

.survey-variable__left {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-right: $spacing2;
    width: 33.3%;

    &--with-paragraph {
        width: 100%;
        padding-right: 0;
    }
}

.survey-variable__label {
    display: block;
    // color: $gray-color-60;
    // &::first-line {
    //     color: $gray-color-80;
    // }
    color: $gray-color-80;
    line-height: $spacing5;

    &--radio,
    &--checkbox {
        line-height: $spacing5;
        padding-top: $spacing0;
    }

    &--select,
    &--autocomplete {
        //line-height: $spacing6;
        margin-top: ($spacing6 - $spacing5) * 0.5;
    }

    &--text,
    &--integer,
    &--float,
    &--date,
    &--datetime,
    &--dataset,
    &--textarea,
    &--externaloptionset {
        // line-height: $spacing6;
        margin-top: ($spacing6 - $spacing5) * 0.5;
    }

    &--paragraph {
        p {
            margin: 0;
        }
    }
}

.survey-variable__icon {
    position: relative;
    top: -4px;
    display: inline-block;
    margin-left: 2px;
    height: 16px;
}

.survey-variable__label__error {
    color: $error-color;
}

.survey-variable__error {
    margin-top: $spacing0;

    &--exception,
    &--error {
        color: $error-color;
    }

    &--warning {
        color: $warning-color;
    }

    &--text {
        color: inherit;
    }
}

.survey-variable__label__info {
    position: relative;
    top: -1px;
    vertical-align: middle;
    color: $control-base-color;
    cursor: pointer;
}

.survey-variable__right {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    flex-basis: 0;

    .lg-calendar--disabled {
        opacity: 0.65;
        color: black;

        .lg-calendar__input,
        .lg-time-picker__input {
            background: $gray-color-2;
            cursor: default;
            border: 1px solid $gray-color-10;
            box-shadow: none;

            .lg-calendar__input__part,
            .lg-time-picker__input__part {
                background: $gray-color-2;
            }
        }
    }

    .survey-input-control--disabled,
    .textarea--disabled {
        opacity: 0.65;
        color: black;
    }
}

.survey-variable__right__group {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
}

.survey-variable__right__group__note {
    margin-left: $spacing2;
    align-self: center;
}

.lg-tooltip--survey-help {
    max-width: 300px;
}

.lg-tooltip--survey-help p {
    margin: 0;
}

.lg-tooltip--survey-help a {
    color: $base-40;
}
